var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('bill.bills'),"icon":"mdi-cash-multiple"}}),_c('v-expansion-panels',{staticClass:"pa-5",attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-icon',{attrs:{"color":"primary darken-1"}},[_vm._v(" mdi-clipboard-text-search-outline ")])],1),_c('v-expansion-panel-content',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[[_c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","outlined":"","dense":"","rules":_vm.rules,"label":_vm.$t(
                                                                'reports.fromDate'
                                                            ),"readonly":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.saveDate},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1)]],2),_c('v-col',{attrs:{"cols":"12","md":"4"}},[[_c('div',[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","outlined":"","dense":"","rules":_vm.rules,"label":_vm.$t(
                                                                'reports.toDate'
                                                            ),"readonly":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker2},on:{"update:activePicker":function($event){_vm.activePicker2=$event},"update:active-picker":function($event){_vm.activePicker2=$event},"change":_vm.saveDate2},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1)]],2),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.billTypesList,"hide-details":"","item-text":"text","item-value":"value","label":_vm.$t('bill.billType'),"outlined":"","dense":""},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1)],1)],1),_c('v-btn',{attrs:{"color":"primary darken-1 white--text","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.getData}},[_c('v-icon',[_vm._v("mdi-clipboard-text-search-outline")]),_vm._v(" "+_vm._s(_vm.$t("preview"))+" ")],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"pa-5",attrs:{"headers":_vm.headers,"items":_vm.bills,"search":_vm.search,"items-per-page":15,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"item.totalAmount",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.totalAmount,"", 3))+" ")]}},{key:"item.totalNet",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.totalNet,"", item.floatingPoints))+" ")]}},{key:"item.billDate",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.billDate,"yyyy-MM-DD"))+" ")]}},{key:"item.billType",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.billTypes[item.billType])+" ")]}},{key:"item.payType",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.payTypes[item.payType])+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","loading":_vm.loading,"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                                                            var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [(_vm.isInRole(37))?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [(_vm.isInRole(38))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])])],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }