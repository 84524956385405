<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header :title="$t('bill.bills')" icon="mdi-cash-multiple" />
            <v-expansion-panels class="pa-5" v-model="panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon color="primary darken-1">
                            mdi-clipboard-text-search-outline
                        </v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="fromDate"
                                                            :label="
                                                                $t(
                                                                    'reports.fromDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fromDate"
                                                        :active-picker.sync="
                                                            activePicker
                                                        "
                                                        @change="saveDate"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu2"
                                                    v-model="menu2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="toDate"
                                                            :label="
                                                                $t(
                                                                    'reports.toDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="toDate"
                                                        :active-picker.sync="
                                                            activePicker2
                                                        "
                                                        @change="saveDate2"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-autocomplete
                                            v-model="selectedType"
                                            :items="billTypesList"
                                            hide-details
                                            item-text="text"
                                            item-value="value"
                                            :label="$t('bill.billType')"
                                            outlined
                                            dense
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="getData"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon
                                    >mdi-clipboard-text-search-outline</v-icon
                                >
                                {{ $t("preview") }}
                            </v-btn>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table
                :headers="headers"
                class="pa-5"
                :items="bills"
                :search="search"
                :items-per-page="15"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template v-slot:[`item.totalAmount`]="{ item }">
                    {{ item.totalAmount | currency("", 3) }}
                </template>
                <template v-slot:[`item.totalNet`]="{ item }">
                    {{ item.totalNet | currency("", item.floatingPoints) }}
                </template>
                <template v-slot:[`item.billDate`]="{ item }">
                    {{ item.billDate | moment("yyyy-MM-DD") }}
                </template>
                <template v-slot:[`item.billType`]="{ item }">
                    {{ billTypes[item.billType] }}
                </template>
                <template v-slot:[`item.payType`]="{ item }">
                    {{ payTypes[item.payType] }}
                </template>
                <template v-slot:top>
                    <v-toolbar flat>
                        <!--Search bar-->
                        <v-text-field
                            v-model="search"
                            dense
                            outlined
                            :loading="loading"
                            append-icon="mdi-magnify"
                            :label="$t('search')"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <!--Delete popup-->
                        <confirm-dialog
                            :openDialog="dialogDelete"
                            :onClicked="deleteItemConfirm"
                            :onClose="closeDelete"
                            toolBarColor="red darken-2"
                        ></confirm-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="text-end">
                        <v-row>
                            <v-col cols="6"
                                ><v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="isInRole(37)"
                                            @click="editItem(item)"
                                            class="ml-2 mr-2"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span> {{ $t("edit") }} </span>
                                </v-tooltip></v-col
                            >
                            <v-col cols="6"
                                ><v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="isInRole(38)"
                                            @click="deleteItem(item)"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="red"
                                        >
                                            mdi-delete-outline
                                        </v-icon>
                                    </template>
                                    <span> {{ $t("delete") }}</span>
                                </v-tooltip></v-col
                            >
                        </v-row>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            date: null,
            date2: null,
            activePicker: null,
            menu: false,
            menu2: false,
            activePicker2: null,
            valid: null,
            fromDate: moment(new Date()).format("yyyy-MM-DD"),
            toDate: moment(new Date()).format("yyyy-MM-DD"),
            selectedType: null,
            panel: [0, 1],
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            headers: [
                {
                    text: this.$t("bill.billNumber"),
                    value: "billNumber"
                },
                {
                    text: this.$t("accountTree.account"),
                    value: "accountName"
                },
                {
                    text: this.$t("bill.billType"),
                    value: "billType"
                },
                {
                    text: this.$t("customer.customerName"),
                    value: "fullName"
                },
                {
                    text: this.$t("bill.payType"),
                    value: "payType"
                },
                {
                    text: this.$t("bill.billDate"),
                    value: "billDate"
                },
                {
                    text: this.$t("currency.currency"),
                    value: "currencySymbol"
                },
                /*   {
                    text: this.$t("bill.totalAmount"),
                    value: "totalAmount"
                }, */
                {
                    text: this.$t("bill.totalNet"),
                    value: "totalNet"
                },
                {
                    text: this.$t("bill.notes"),
                    value: "notes"
                },

                { text: "", value: "actions" }
            ],
            billTypes: {
                Purchase: this.$t("billTypes.Purchase"),
                Sale: this.$t("billTypes.Sale"),
                PurchaseReturn: this.$t("billTypes.PurchaseReturn"),
                SaleReturn: this.$t("billTypes.SaleReturn"),
                InstallmentsSale: this.$t("billTypes.installmentsSaleBill")
            },
            billTypesList: [
                {
                    text: this.$t("billTypes.Purchase"),
                    value: "Purchase"
                },
                {
                    text: this.$t("billTypes.Sale"),
                    value: "Sale"
                },
                {
                    text: this.$t("billTypes.PurchaseReturn"),
                    value: "PurchaseReturn"
                },
                {
                    text: this.$t("billTypes.SaleReturn"),
                    value: "SaleReturn"
                },
                {
                    text: this.$t("billTypes.InstallmentsSale"),
                    value: "InstallmentsSale"
                }
            ],
            payTypes: {
                1: this.$t("payTypes.cash"),
                2: this.$t("payTypes.delayed")
            },
            bills: [],
            editedItem: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: null,
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscont: null,
                totalExtra: null,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: null,
                isAccept: null,
                isPaid: null,
                vouchers: null,
                remainingAmount: null,
                isMaintaince: null,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null
            },
            defaultItem: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: null,
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscont: null,
                totalExtra: null,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: null,
                isAccept: null,
                isPaid: null,
                vouchers: null,
                remainingAmount: null,
                isMaintaince: null,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        if (this.isInRole(43)) {
            this.redirectUnauthorizedUsers();
        }
        this.getData();
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.bills.indexOf(item);
            Object.assign(this.editedItem, item);

            var routeName = "";

            /*
                Hard Coded !!!!!!!!!!!
            */
            if (this.editedItem.billType == "Purchase")
                routeName = "bill.purchaseBill";
            else if (this.editedItem.billType == "Sale")
                routeName = "bill.saleBill";
            else if (this.editedItem.billType == "PurchaseReturn")
                routeName = "bill.purchaseReturnBill";
            else if (this.editedItem.billType == "SaleReturn")
                routeName = "bill.saleReturnBill";
            else if (this.editedItem.billType == "InstallmentsSale")
                routeName = "bill.installmentsSaleBill";

            this.$router.push({
                name: routeName,
                params: { id: this.editedItem.billGuid },
                props: { vType: this.editedItem.billType }
            });
        },
        deleteItem(item) {
            this.editedIndex = this.bills.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.bills[this.editedIndex];
            axios
                .delete("Bills/DeleteBill?id=" + deleteItem.billGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.getData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.getData();
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getData() {
            try {
                this.loading = true;
                axios
                    .get("Bills/GetBills", {
                        params: {
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                            selectedType: this.selectedType
                        }
                    })
                    .then(response => {
                        this.bills = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        saveDate2(date) {
            this.$refs.menu2.save(date);
        }
    }
};
</script>
